<template>
  <section class="slothprotect">
    <h1 class="text-white font-weight-bolder">SlothProtect</h1>
    <div class="description mb-2">
      <p class="text-white">
        We’ll cross-reference your email with an updated database of company data leaks and the dark web. This report reveals compromised data, including passwords, personal information, and more. Explore as many email addresses as you like. We recommend checking your data security monthly 👩🏼‍💻
      </p>
    </div>

    <form class="miniform mt-3" @submit.prevent>
      <div class="text-center mb-2">
        <img
          :src="`${urlBucket}/dashboard/slothprotect-header-miniform.png`"
          class="img-fluid"
          width="335px"
          alt=""
        />
      </div>

      <div class="mb-2">
        <p class="text-white text-left font-weight-bolder">
          Which email shall we check?
        </p>

        <input type="text" :placeholder="'Email'" class="w-100 p-1" v-model="email" />

        <p
          class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
          v-if="emailInvalid"
        >
          * This email is invalid
        </p>
      </div>

      <button
        class="d-flex justify-content-center button"
        @click="submit()"
        :disabled="loading"
      >
        <p class="mb-0 font-weight-bolder mr-1" style="font-size: 18px;">Check My Data</p>
        <div class="spinner-border text-light" role="status" v-if="loading"></div>
      </button>

      <div class="mt-3" v-if="htmlReport">
        <div v-html="htmlReport"></div>
      </div>
      <!-- <h1 class="font-weight-bolder" style="color: #ffe859">Adam's Report</h1>
      <p class="font-weight-bolder">7th August</p> -->
    </form>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axiosCustom from "@axios";
import Vue from "vue";
import axios from "axios";

export default {
  name: "SlothProtect",
  components: {},
  data() {
    return {
      loading: false,
      email: "",
      emailInvalid: false,
      htmlReport: null,
    };
  },
  mounted() {

    // axios.get("/breach-2.html").then((html) => {
    //   // console.log(html);
    //   document.querySelector(".miniform").style.maxWidth = "max-content";
    //   this.htmlReport = html.data;
    //   /* let iframe = document.getElementById("iframe-report");
    //   iframe.src = "data:text/html;charset=utf-8," + escape(html.data); */
    // });
    
  },
  methods: {
    submit() {
      const regexMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (regexMail.test(this.email)) {
        this.emailInvalid = false;

        let body = {
          email: this.user.email,
          preferredEmail: this.email,
        };

        console.log(body);

        // return;
        this.loading = true;
        axiosCustom
          .post(`/dashboard/slothProtect`, body)
          .then((result) => {
            if (result.data.report) {
              console.log(result.data);
              document.querySelector(".miniform").style.maxWidth = "max-content";
              this.htmlReport = result.data.report;
            }
          })
          .catch((err) => {})
          .finally(() => (this.loading = false));
      } else {
        this.emailInvalid = true;
      }
    },
  },
  computed: {
    ...mapState("app", ["user", "urlBucket", "currentMove", "logoPartner"]),
    ...mapGetters("app", ["isAPartner", "colorPartner"]),
  },
  watch: {},
};
</script>

<style lang="scss">
.slothprotect {
  margin-left: 12em;

  @media screen and (max-width: 900px) {
    margin-left: 0;
  }
  
  h1 {
    font-weight: bold;
  }
  p {
    /*color: white;*/
    font-size: 16px;
  }

  .miniform,
  .description {
    max-width: 550px;
  }

  .description p{
    font-size: 16px;
    line-height: 1.7;
  }

  input[type="text"] {
    outline: none;
    border-radius: 0.375rem;
    border: none;
    color: black;
    font-size: 16px;
  }

  .miniform {
    border: 5px solid #3ee185;
    border-radius: 30px;
    background-color: #212534;
    padding: 27px 40px;
    padding-top: 15px;

    .question-error {
      font-size: 14px;
      color: #c64a45;
    }

    .selection {
      .col {
        max-width: 125px;
      }

      .squares {
        text-align: center;
        background: #fff;
        border-radius: 5px;
        cursor: pointer;
        color: #000;
        box-shadow: 0 1px 7px rgba(11, 45, 150, 0.2);
        transition: all 0.1s ease-in-out;

        img {
          width: 70px;
          height: 67px;
        }

        p {
          color: #000;
        }
      }

      .selected {
        background-color: #00b9ee;

        p {
          color: white;
        }
      }
    }

    /*
    p {
      color: white;
    }
    */

    .label {
      background-color: rgba(57, 61, 74, 0.62);
      border-radius: 10px;
      padding: 6px;
    }

    button {
      all: unset;
      box-sizing: border-box;
      width: 100%;
      display: block;
      padding: 15px;
      color: white;
      text-align: center;
      letter-spacing: 1.5px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 13px;
      font-size: 16px;
    }

    .button {
      background-color: #ec0677;
      box-shadow: 0 5px 0 #7d0741;
    }

    .button:active {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
}
</style>
